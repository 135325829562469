<template>
  <div>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      @click="handleExport()"
      v-if="isShow"
    >
      导出Excel
    </b-button>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      v-b-modal="`add-general-group`"
      v-if="isShowGroup"
    >
      添加组
    </b-button>
  </div>
</template>

<script>
import {
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BButton,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

import * as XLSX from "xlsx";
// import XLSX from "xlsx";
import FileSaver from "file-saver";
export default {
  props: {
    wExport: {
      type: String,
      default: "all",
    },
  },
  components: {
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      aoa: [],
      aoaTitle: "",
    };
  },
  computed: {
    isShow() {
      return this.$store.state.exportExcel.isShowAllExcelBtn;
    },
    isShowGroup() {
      return this.$store.state.exportExcel.isShowAddGroupBtn;
    },
  },
  mounted() {},
  methods: {
    handleExport() {
      this.aoa = this.$store.state.exportExcel.totalDataArr;
      this.aoaTitle =
        this.$store.state.exportExcel.totalDataArrTitle || "数据汇总";
      console.log(this.aoa, "aoa");
      console.log(this.aoaTitle, "aoaTitle");
      if (this.wExport === "all") {
        this.allExportExcel(this.aoa);
      } else {
        this.oneExportExcel();
      }
    },
    // aoa_to_sheet: 这个工具类最强大也最实用，将一个二维数组转成sheet，会自动处理number、string、boolean、date等类型数据
    // table_to_sheet: 将一个table dom直接转成sheet，会自动识别colspan和rowspan并将其转成对应的单元格合并；
    // json_to_sheet: 将一个由对象组成的数组转成sheet；
    allExportExcel(aoa) {
      const filename = `${this.aoaTitle}.xlsx`;
      // Excel第一个sheet的名称
      const ws_name = "Sheet1";
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.aoa_to_sheet(aoa);
      // const ws = XLSX.utils.json_to_sheet(aoa);
      XLSX.utils.book_append_sheet(wb, ws, ws_name); // 将数据添加到工作薄
      XLSX.writeFile(wb, filename); // 导出Excel
    },

    oneExportExcel() {
      var xlsxParam = { raw: true }; // 导出的内容只做解析，不进行格式转换
      var wb = XLSX.utils.table_to_book(
        document.querySelector("#" + id),
        xlsxParam
      );
      var wbout = XLSX.write(wb, {
        bookType: "xlsx",
        bookSST: true,
        type: "array",
      });
      try {
        FileSaver.saveAs(
          new Blob([wbout], { type: "application/octet-stream" }),
          "table.xlsx"
        );
      } catch (e) {
        if (typeof console !== "undefined") {
          console.log(e, wbout);
        }
      }
      return wbout;
    },
  },
};
</script>