export default [
  // {
  //   title: '首页看板',
  //   route: 'home',
  //   icon: 'HomeIcon',
  // },
  // {
  //   title: 'Second Page',
  //   route: 'second-page',
  //   icon: 'FileIcon',
  // },
  {
    title: '商家服务',
    icon: 'BriefcaseIcon',
    children: [
      {
        title: '跑腿管理',
        route: 'errandManagement',
      },
      {
        title: '门店设置',
        route: 'storeSetting',
      },
      {
        title: '打印机',
        route: 'printer',
      },
      {
        title: '支付配置',
        route: 'paySetting',
      },
      {
        title: '支付申请',
        route: 'payApplication',
      },


    ]
  },
  {
    title: '商户跟进',
    icon: 'CreditCardIcon',
    children: [
      {
        title: '商户查询',
        route: 'merchantInquire',
      },
      {
        title: '续费通知',
        icon: 'MessageSquareIcon',
        children: [
          {
            title: '短信监控',
            route: 'SMSmonitoring',
          },
          {
            title: '即将逾期',
            route: 'immediatelyOverdue',
          },
        ]
      }
    ]
  },
  {
    title: '联盟管理',
    icon: 'CommandIcon',
    children: [
      {
        title: '联盟客',
        route: 'allianceUser',
      },
      {
        title: '统计报表',
        route: 'statisticalReport',
      },
      {
        title: '佣金发放',
        route: 'commissionDistribution',
      },
    ]
  },

  {
    title: '退出登录',
    route: { name: 'LoginOut' },
    icon: 'LogOutIcon',
  },

]
